<script setup>
import {computed} from "vue";

var props = defineProps({
	status: {
		type: String,
		default: null
	},
	size: {
		type: String,
		default: '4'
	}
})

const sizeClases = computed(() => {
	return [
		'h-' + props.size,
		'w-' + props.size,
		'absolute bottom-0 right-0 block',
		'rounded-full',
		'ring-2 ring-white'
	]
})

</script>

<template>
		<span
				v-if="(status == null || status === 'none')"
				:class="sizeClases"
				class="bg-gray-500"/>
	<span v-else-if="status === 'fully'"
				:class="sizeClases"
				class="bg-green-500"/>
	<span v-else-if="status === 'partial'"
				:class="sizeClases"
				class="bg-yellow-500"/>
	<span v-else-if="status === 'rejected'"
				:class="sizeClases"
				class="bg-red-500"/>
</template>

<style scoped>

</style>